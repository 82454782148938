.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.p-fixed{
  position:fixed;
  z-index:10;
}
.p-relative{
  position:relative;
}
.d-flex{
  display:flex;
}
.d-none{
  display:none;
}
.d-inline{
  display:inline;
}
.d-block{
  display:block;
}
.w-100p{
  width:100%
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  display: inline-block;
  vertical-align: top;
  width: 50px;
  height: 50px;
  margin-right: 20px;
  margin-top: 15px;    /* if you want it vertically middle of the navbar. */
}

/* Add a black background color to the top navigation */
.Navbar {
  background-color: rgb(58, 5, 65);
  overflow: hidden;
  width:100%;
  min-height:10%;
  display: flex;
}

.Navbar .leftSide{
flex:50%;
margin-top:40px;
}

.Navbar .leftSide .links{
 max-height: 80px;
 display:inline;
 padding:15px;
 margin:1px;
 font-weight: 300;
 font-size: 1.2rem;
 line-height: 3rem;
}

  .Navbar .leftSide .links a{
    text-decoration: none;
    color:white;
    }

.Navbar .rightSide{
  flex:50%;
  }

  .Navbar .title{
    color:white;
    padding:10px;
    padding-top:0px;
  }


  /* CSS for draftjs editor in AddNoteForm */
  .wrapper-class {
    max-width: 98%;
    padding: 1rem;
    border: 1px solid #ccc;
  }
  .editor-class {
    background-color:lightgray;
    padding: 1rem;
    border: 1px solid #ccc;
  }
  .toolbar-class {
    border: 1px solid #ccc;
  }