.outer-containers{
    width:99%; 
}
.video-container {
    position: relative;    
    padding: 5px;
    height: 0;
    overflow: hidden;
    width: 100%;
    min-height: 300px;   
}
.video-container iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;    
}
