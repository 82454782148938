.dot {
    height: 45px;
    width: 45px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }

  .circle {
    background-color: #04AA6D; /* Green */
    width: 40px;
    height: 40px;
    border: none;
    color: white;
    padding: 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    margin: 4px 2px;
    cursor: pointer;
  }

  .round {border-radius: 50%;}

  .rounded-button{
    width: 3rem;
    height: 3rem;    
    background: black;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    justify-content: center;  
    color: #ffffff;  
    border-radius: 50%;
    border: none;
    text-decoration: none;
    transition: background 0.2s;
    box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);
  }

  
.rounded-button:hover {
  
  background: #cd201f;
}

.rounded-button:hover .triangle{
  display: inline-block;
}

.rounded-button:hover .num{
  display: none;
}

.triangle {
  display: none;
    width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 0 7px 14px;
  border-color: transparent transparent transparent #ffffff;
  margin: 0 auto;
}